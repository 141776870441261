<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import moment from "moment";

export default {
    page: {
        title: "Buat Surat Tugas Validasi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Rapat Banding",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },    
                {
                    text: "Rapat Banding",
                    active: "/pasca-asesmen/daftar-banding-admin",	
                },
                {
                    text: "Detail Rapat Banding",
                    active: true,
                },
                
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],
            id_rapat_banding: this.$route.params.id,
            data_rapat_banding: [],
            data_asesmen: [],
            next_status: [],
            status_sekarang: [],
            loadingTable: false,
            data_asesor_banding : [],
            sessionRoleId: localStorage.getItem("session_role_active_id"),
            sessionUserId: localStorage.getItem("session_user_id"),
            sessionUsername: localStorage.getItem("session_username"),
            data_dokumen: [],
            asesi_id: null,
            asesi_nama: null,
            hak_akses: false,
                
            // ubah status
            status_ketersediaan_asesor : null,
            id_ketersediaan_asesor : null,
            index_ketersediaan_asesor : null,
            preview_surat_tugas : null ,
            
            //berita acara
            preview_berita_acara : null,
            disclaimer_berita_acara : false,

            //surat keputusan
            preview_surat_keputusan : null,
            disclaimer_surat_keputusan : false,

            // blanko sertifikat
            preview_blanko : null,
            disclaimer_blanko : false,
            path_surat_blanko : null,
            
            statusBanding : null,
            path_berita_acara : null,
            path_surat_keputusan : null,
            is_draft_berita_acara : null,
            password : null,

            data_asesi : [],
            // variable untuk kepala tuk
            backend_url: process.env.VUE_APP_BACKEND_URL,
            disclaimer_surat_tugas : false,
            showModalPassword : false,
            showModalPersetujuanKetuaLsp : null,
            showModalPasswordAsesor : false,
            
            esign_berita_acara : false,

            // variabel modal 
            showLoading: false,
            showModalSimpan: false,
            showModalDraftBeritaAcara : false,
            showModalEsignBeritaAcara : false,
            showModalSuratKeputusan : false,
            showPreviewBeritaAcara : false,
            showPreviewSuratTugas : false,
            showModalBlanko : false,
            showModalPreviewSurat : false,
            preview_surat : null,
            showModalPengisianDokumen : false,
            showModalPreviewDokumen : false,
            qr_status : false,
            showModalDokumen   : false,

            // variabel form dokumen 
            form_dokumen_muk : null,
            dokumen_id_selected : null,
            rapat_banding_dokumen_id : null,
            loadingformPreviewDoc : false,

            path_data_excel : null,
            qr_path : null,
            role_super_admin : '99c668b7-015c-4b68-9761-d2393129d070',
            role_admin_lsp : '99d70245-6f47-43b6-98d3-b61579bedce1',

            // variable timeline
            rapat_banding_timeline : [],
            asesmen_id: null,
            loadingShowModalDokumen: false,
            showModalPreviewDokumenBeritaAcara: false

            }
    },
    mounted() {
        this.getDetailRapatBanding();
    },  
    methods: {
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD");
        },
        getDetailRapatBanding() {            
            let self = this;
            self.loadingTable = true;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/detail",
                params: {
                    id: self.id_rapat_banding,
                    role_id : localStorage.session_role_active_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.status == true) {
                        var data_edit = response_data_fix;
                        self.data_rapat_banding = data_edit;
                        self.next_status = data_edit.next_status;
                        self.status_sekarang = data_edit.status;
                        self.data_asesmen = data_edit.rapat_banding_detail;
                        self.data_asesor_banding = data_edit.asesor;
                        self.data_asesi = data_edit.asesi;
                        console.log(self.data_asesi);
                        self.hak_akses = response_data.hak_akses;

                        self.rapat_banding_timeline = data_edit.rapat_banding_timeline;
                        // console.log(self.rapat_banding_timeline);
                        // if (self.data_rapat_banding?.status_id == 'd7f4f754-5c18-11ee-bc25-0242ac110007') {
                        //     self.is_draft_berita_acara = false;
                        //     self.generateDraftBeritaAcara();
                        // }
                        if (self.data_rapat_banding?.status_id == '8c9dccd0-68d5-11ee-b93b-0242ac11000a') {
                            self.is_draft_berita_acara = true;
                        }
                        self.loadingTable = false;
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },

        getDokumenAsesmenAsesi(asesi_id, asesmen_id) {            
            let self = this;
            self.showModalDokumen = true;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/get-dokumen-asesmen-asesi",
                params: {
                    id_asesi: asesi_id,
                    id_asesmen : asesmen_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.status == true) {
                        self.data_dokumen = response_data_fix;
                        self.loadingShowModalDokumen = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
       
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
        statusBandingModal(status, asesi_id) {
            let self = this;
            self.asesi_id = asesi_id;
            console.log(self.asesi_id);
            console.log(self.asesi_id);
            if (status == "tolak") {
                self.showModalValidasi = true;
                self.statusBanding = status;
            } else if  (status == "submit-ketua-lsp") {
                self.showModalPassword = false;
                self.simpanSurat();
            } else if  (status == "submit-blanko") {
                self.showModalPassword = false;
                self.statusBanding = status;
                self.simpanSurat();
            } else if  (status == "submit-draft-berita-acara") {
                self.showModalPasswordAsesor = false;
                self.statusBanding = status;
                self.simpanSurat();
            } else if  (status == "submit-keputusan") {
                self.showModalPassword = false;
                self.statusBanding = status;
                console.log(self.statusBanding);
                self.simpanSurat();
            } else if  (status == "submit-esign-berita-acara") {
                self.showModalPasswordAsesor = false;
                self.statusBanding = status;
                self.simpanSurat();
            }else if  (status == "submit-proses-investigasi") {
                self.showModalPassword = false;
                self.simpanSurat();
            }else if  (status == "submit-proses-investigasi") {
                self.showModalPassword = false;
                self.simpanSurat();
            } else if  (status == "submit-pengisian-berita-acara") {
                self.showModalPassword = false;
                self.statusBanding = status;
                self.simpanSurat();
            } else if  (status == "esign-manager-sertifikasi") {
                self.showModalPassword = true;
            } else if  (status == "esign-ketua-lsp") {
                self.showModalPassword = true;
            } else if  (status == "submit-esign-manager-sertifikasi") {
                self.showModalPassword = false;
                self.simpanSurat();
            } else if  (status == "submit-esign-ketua-lsp") {
                self.showModalPassword = false;
                self.simpanSurat();
            }else {
                self.showModalValidasi = true;
                self.statusBanding = status;
            }
        },
     
        simpanSurat(){
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/ubah-status",
                data: {
                    id: self.id_rapat_banding,
                    next_status : self.next_status.id,
                    asesi_id : self.asesi_id,
                    status_banding : self.statusBanding,
                    path_berita_acara : self.path_berita_acara,
                    path_surat_keputusan : self.path_surat_keputusan,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    if ((self.statusBanding == "submit-esign-berita-acara"  || self.statusBanding == "submit-keputusan" ) 
                    && (self.sessionRoleId == '99dc7617-56b7-4e69-950b-1da996a2250d' || self.sessionRoleId == '99d70245-6f47-43b6-98d3-b61579bedce1') ){
                        let res = response.data.data;
                        // console.log(res);
                        self.next_status = res.next_status;
                        self.status_sekarang = res.status;
                        console.log(res.next_status);
                        if(self.next_status.id == 'fe6ffc44-5c18-11ee-bc25-0242ac110007'){
                            self.ModalSuratKeputusan();
                        }
                        if(self.next_status.id == '26cdb82a-5c19-11ee-bc25-0242ac110007'){
                            self.ModalBlanko();
                        }
                        
                        else{
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Anda akan diarahkan ke halaman list rapat Banding",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                            }).then((result) => {
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    Swal.close();
                                    // close all modal
                                    self.$router.push({ name: "daftar-banding-admin" });
                                }
                            });
                        }
                    }
                   else {
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman list rapat Banding",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            // close all modal
                            self.$router.push({ name: "daftar-banding-admin" });
                        }
                    });
                   }
                })
                .catch(function (error) {
                    // this.axiosCatchError = e.response;
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    console.log(error);
                    self.axiosCatchError = error.response.data.data.error;
                });
        },
        verifikasiKetersediaan(status_ketersediaan, id_item, index) {
            let self = this;
            self.status_ketersediaan_asesor = status_ketersediaan;
            self.id_ketersediaan_asesor = id_item;
            self.index_ketersediaan_asesor = index;
            if (status_ketersediaan == "Tidak Bersedia") {
                self.showModalStatusKetersediaan = true;
            } else {
                self.prosesVerifikasiKetersediaan();
            }
        },
        prosesVerifikasiKetersediaan() {
            let self = this;
            self.showModalStatusKetersediaan = false;
            var index = self.index_ketersediaan_asesor;
            var id_asesor = self.id_ketersediaan_asesor;
            var keterangan_ketersediaan = self.status_ketersediaan_asesor;
            self.data_asesor_banding[index].is_loading = true;
            axios
                .put(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/ubah-status-ketersediaan-asesor",
                    {
                        rapat_banding_id: self.id_rapat_banding,
                        id_asesor: id_asesor,
                        keterangan_ketersediaan: keterangan_ketersediaan,
                        notes: self.catatan_ketersediaan,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    console.log(response);
                    self.data_asesor_banding[index].is_loading = false;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman list rapat Banding",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.showModalStatusKetersediaan = false;
                            Swal.close();
                            self.$router.push({ name: "daftar-banding-admin" });
                        }
                    });
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        ModalPersetujuanKetuaLsp() {
            let self = this;
            self.showModalPersetujuanKetuaLsp = false;
            self.showLoading = true;
            self.$nextTick(() => {
                self.showModalPersetujuanKetuaLsp = true;
            });
            self.generateSuratTugas();
        },
        ModalPassword(){
            let self = this
            self.showModalPersetujuanKetuaLsp = false
            self.showModalPassword = false
            self.showModalSuratKeputusan = false
            self.showModalBlanko = false
            self.$nextTick(() => {
                self.showModalPassword = true
            })
        },
        ModalPasswordAsesor(){
            let self = this
            self.showModalDraftBeritaAcara = false
            self.showModalEsignBeritaAcara = false
            self.showModalPasswordAsesor = false
            self.$nextTick(() => {
                self.showModalPasswordAsesor = true
            })
        },
        ModalDraftBeritaAcara(){
            let self = this
            self.showModalDraftBeritaAcara = false
            self.$nextTick(() => {
                self.showModalDraftBeritaAcara = true
            })
        },
        ModalEsign(){
            let self = this
            self.showModalEsignBeritaAcara = false
            self.$nextTick(() => {
                self.showModalEsignBeritaAcara = true
                self.is_draft_berita_acara = false;
                self.generateDraftBeritaAcara();
            })
        },
        ModalSuratKeputusan(){
            let self = this
            self.showModalSuratKeputusan = false
            self.$nextTick(() => {
                self.showModalSuratKeputusan = true
                self.generateSuratKeputusan()
            })
        },
        generateDraftBeritaAcara(){
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/generate-berita-acara",
                params: {
                    rapat_banding_id: self.id_rapat_banding,
                    is_draft_berita_acara : self.is_draft_berita_acara,
                    qr : self.qr_status,
                },
                
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            }
            axios(config)
                .then(function (response) {
                        Swal.close();
                        let res = response.data.data;
                        self.showPreviewBeritaAcara = true;
                        self.preview_berita_acara = res.pdf;
                        self.path_berita_acara = res.data_berita_acara.path_berita_acara;
                        self.qr_path = res.data_berita_acara.qr_path;
                        if (self.data_rapat_banding?.status_id != 'd7f4f754-5c18-11ee-bc25-0242ac110007'){
                            self.next_status = res.data_berita_acara.next_status;
                        }

                    })
        },
        generateSuratKeputusan(){
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading Gnerate Surat Keputusan...",
                showConfirmButton: false,
            });

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-pleno/generate-surat-keputusan",
                params: {
                    rapat_banding_id: self.id_rapat_banding,
                    qr : true
                },
                
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            }
            axios(config)
                .then(function (response) {
                        Swal.close();
                        let res = response.data.data;
                        self.preview_surat_keputusan = res.pdf;
                        self.path_surat_keputusan = res.data_surat_keputusan.path_surat_keputusan;
                        self.next_status = res.data_surat_keputusan.next_status;
                        self.data_rapat_banding.status_id = res.data_surat_keputusan.status.id;
                        self.data_rapat_banding.status_nama = res.data_surat_keputusan.status.status_nama;
                    })
        },
        generateSuratTugas(){
            let self = this;
            self.showLoading = true;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/generate-surat-tugas",
                params: {
                    rapat_banding_id: self.id_rapat_banding,
                    qr : true
                },
                
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            }
            axios(config)
                .then(function (response) {
                        self.showLoading = false;
                        let res = response.data.data;
                        self.preview_surat_tugas = res.pdf;
                        self.qr_path = res.data_rapat_banding.qr_path;
                        self.path_surat_tugas = res.data_rapat_banding.path_surat_tugas;
                        self.next_status = res.data_rapat_banding.next_status;
                        
                    })
                    
        },
        ModalPreviewSurat(surat){
            let self = this
            self.showModalPreviewSurat = false
            if (surat == 'surat_tugas'){
                self.preview_surat = self.backend_url +'/'+ self.data_rapat_banding.path_surat_tugas
                console.log(self.preview_surat);
            } else if (surat == 'berita_acara'){
                self.preview_surat = self.backend_url +'/'+ self.data_rapat_banding.path_berita_acara
            } else if (surat == 'surat_keputusan'){
                self.preview_surat = self.backend_url +'/'+ self.data_rapat_banding.path_surat_keputusan
            } else if (surat == 'blanko'){
                self.preview_surat = self.backend_url +'/'+ self.data_rapat_banding.path_surat_permohonan_blanko
            } 
            self.$nextTick(() => {
                self.showModalPreviewSurat = true
            })
        },
        pengisianDokumenBeritaAcara(asesi_id) {
            let self = this;
            self.form_dokumen_muk = '<i class="fas fa-spinner fa-spin"></i> Loading...';
            self.showModalPengisianDokumen = true;
            self.showModalDokumen = false;
            self.asesi_id = asesi_id;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/get-form-dokumen-berita-acara", {
                    params: {
                        rapat_banding_id: self.id_rapat_banding,
                        asesi_id: asesi_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    // var res_fix = response.data.data;
                    self.form_dokumen_muk = response.data.html;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        ModalDokumen(asesi_id , asesi_nama, asesmen_id) {
            let self = this
            self.asesi_id = asesi_id,
            self.asesi_nama = asesi_nama,
            self.asesmen_id = asesmen_id,
            self.showModalDokumen = false,
            self.loadingShowModalDokumen = true,
            self.getDokumenAsesmenAsesi(asesi_id,asesmen_id);
        },
        storeInputDokumen() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let self = this;
            var form = $("#form-dokumen");
            var mydata = new FormData(form[0]);
            mydata.append("rapat_banding_id", self.id_rapat_banding);
            mydata.append("asesi_id", self.asesi_id);
            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/store-form-dokumen", mydata, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_data = response.data;
                    console.log(res_data);
                    self.getDetailRapatBanding();
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Pengisian dokumen berhasil disimpan",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    self.showModalPengisianDokumen = false;
                })
                .catch((e) => {
                    self.axiosCatchError = e.response;
                    console.log(e);
                });
        },
        getFormDokumenBeritaAcara(asesi_id) {
            let self = this;
            self.formPreviewDoc = null;
            self.showModalDokumen = false;
            self.showModalPreviewDokumenBeritaAcara = true;
            self.loadingformPreviewDoc = true;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/preview-dokumen-berita-acara", {
                    params: {
                        rapat_banding_id: self.id_rapat_banding,
                        asesi_id: asesi_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data;
                    self.loadingformPreviewDoc = false;
                    self.formPreviewDoc = res_fix.data.pdf;
                    // is_ttd_asesi = false;
                    // if(res_fix.data.dokumen_asesmen.status_dokumen == 'sudah-input' && (dokumen_peninjau.includes(dokumen.dokumen_id) || metode_dokumen.includes(dokumen.dokumen_id))){
                    //     is_ttd_asesi = true;
                    // }
                    // self.is_ttd_asesi = is_ttd_asesi;
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        getFormDokumen(id, dokumen_id, asesi_id) {
            let self = this;
            self.formPreviewDoc = null;
            self.showModalDokumen = false;
            self.showModalPreviewDokumen = true;
            self.loadingformPreviewDoc = true;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "asesmen/preview-dokumen", {
                    params: {
                        asesmen_dokumen_id: id,
                        dokumen_id: dokumen_id,
                        asesi_id: asesi_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data;
                    self.loadingformPreviewDoc = false;
                    self.formPreviewDoc = res_fix.data.pdf;
                    // is_ttd_asesi = false;
                    // if(res_fix.data.dokumen_asesmen.status_dokumen == 'sudah-input' && (dokumen_peninjau.includes(dokumen.dokumen_id) || metode_dokumen.includes(dokumen.dokumen_id))){
                    //     is_ttd_asesi = true;
                    // }
                    // self.is_ttd_asesi = is_ttd_asesi;
                    console.log(res_fix);
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        DownloadExcel(){
            // let self = this;
            // downloadFile(self.path_data_excel);
            window.open(this.backend_url +'/'+ this.data_rapat_banding.path_data_excel_asesi, '_blank');
        },
        DownloadZIP(){
            let self = this;
            let config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-pleno/download-zip",
                params: {
                    rapat_banding_id: self.id_rapat_banding,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
                responseType: 'blob',
            };
            axios(config)
                .then(function (response) {
                    var blob = new Blob([response.data], { type: 'application/zip' })
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Rapat Banding.zip'
                    link.click()
                })
                .catch(function (error) {
                    console.log(error);
                });  
        },
        closeModalPreviewDokumen(){
            let self = this;
            self.showModalPreviewDokumen = false;
            self.showModalDokumen = true;
        },
        closeModalPreviewDokumenBeritaAcara(){
            let self = this;
            self.showModalPreviewDokumenBeritaAcara = false;
        }
    },
};
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' ||
                                    axiosCatchError instanceof String
                                    ">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <h3>Detail Rapat Banding</h3>
                                    </div>
                                    <div class="col-lg-12">
                                        <table class="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 350px"><strong>Nomor Jadwal Rapat Banding</strong></td>
                                                    <td> {{ data_rapat_banding?.rapat_banding_nomor }}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Tanggal Rapat Banding</strong></td>
                                                    <td> {{ data_rapat_banding?.rapat_banding_tanggal }} </td>

                                                </tr>
                                                <tr>
                                                    <td><strong>Perihal</strong></td>
                                                    <td>{{ data_rapat_banding?.perihal }} </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Tempat Pelaksanan Rapat Banding</strong></td>
                                                    <td> {{ data_rapat_banding?.tuk_nama }}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Status</strong></td>
                                                    <td> {{ data_rapat_banding?.status_nama }} </td>
                                                </tr>
                                                <tr v-if="data_rapat_banding?.path_berita_acara != null">
                                                    <td><strong>Surat Berita Acara</strong></td>
                                                    <td> <button class="btn btn-primary btn-sm" @click="ModalPreviewSurat('berita_acara')"><i class="fas fa-eye"></i> Lihat Surat Berita Acara</button></td>
                                                </tr>
                                                <tr v-if="data_rapat_banding?.path_surat_keputusan != null">
                                                    <td><strong>Surat Keputusan</strong></td>
                                                    <td> <button class="btn btn-primary btn-sm" @click="ModalPreviewSurat('surat_keputusan')"><i class="fas fa-eye"></i> Lihat Surat Surat Keputusan</button></td>
                                                </tr>
                                                <tr v-if="data_rapat_banding?.path_data_excel_asesi != null">
                                                    <td><strong>Data Excel Asesi</strong></td>
                                                    <td><button class="btn btn-primary btn-sm" @click="DownloadExcel()"><i class="fas fa-file-excel"></i> Download Data Excel Asesi</button></td>
                                                </tr>
                                                <tr v-if="data_rapat_banding?.path_data_excel_asesi != null">
                                                    <td><strong>Data ZIP</strong></td>
                                                    <td><button class="btn btn-primary btn-sm" @click="DownloadZIP()"><i class="fas fa-file-excel"></i> Download ZIP</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- <div class="col-12 mt-4">
                                        <label for="example-text-input" class="col-form-label mt-4">List Asesmen</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mb-4">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th style="width: 50px">No</th>
                                                    <th class="text-center" style="width: auto">kode jadwal asesmen</th>
                                                    <th class="" style="width: auto">Asesi</th>
                                                    <th class="" style="width: auto">Asesor Kompetensi</th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-center">
                                                <tr v-if="data_asesmen.length == 0">
                                                    <td class="text-center" colspan="8">Data Tidak Tersedia</td>
                                                </tr>
                                                <tr v-else v-for="(data, index) in data_asesmen" :key="index">
                                                    <td> {{ index + 1 }} </td>
                                                    <td>{{ data.asesmen_nomor }} </td>
                                                    <td> 
                                                        <ul>
                                                            <li v-for="(asesi, index) in data.asesmen_asesi" :key="index">
                                                                {{ asesi.asesi.npk }} - {{ asesi.asesi_nama }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td> 
                                                        <ul>
                                                            <li v-for="(asesor, index) in data.asesmen_asesor_kompetensi" :key="index">
                                                                {{ asesor.pegawai.npk }} - {{ asesor.asesor_nama }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> -->
                                     <div class="col-12 mt-4">
                                        <label for="example-text-input" class="col-form-label mt-4">List Asesor Banding</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mb-4">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th style="width: 50px">No</th>
                                                    <th class="text-center" style="width: 40%">Nama Asesor Banding</th>
                                                    <th class="text-center" style="width: auto">Kehadiran</th>
                                                    <th class="text-center" style="width: auto">Surat tugas</th>

                                                </tr>
                                            </thead>
                                                <tbody class="text-center">
                                                <tr v-if="data_asesor_banding.length == 0">
                                                    <td class="text-center" colspan="8">Data Tidak Tersedia</td>
                                                </tr>
                                                <tr v-else v-for="(data, index) in data_asesor_banding" :key="index">
                                                    <td> {{ index + 1 }} </td>
                                                    <td>{{ data.asesor_npk }} - {{  data.asesor_nama  }} </td>
                                                    <td class="text-center">
                                                        <div v-if="data.asesor_id == sessionUserId || data.asesor_npk == sessionUsername || sessionRoleId == role_super_admin || sessionRoleId == role_admin_lsp">
                                                            <div v-if="data?.is_loading"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                                                            <div class="btn-group" v-else-if="data.keterangan_ketersediaan == 'Bersedia'">
                                                                <b-button v-if="data_rapat_banding?.status_id == 'f1d3f1e8-68ac-11ee-b509-0242ac11000a'" 
                                                                    v-on:click="verifikasiKetersediaan('Roleback', data.id, index)" type="button" variant="warning" class="btn-sm">
                                                                    <i class="bx bx-loader"></i>Roleback</b-button>
                                                                <button class="btn btn-success btn-sm" type="button"><i class="fas fa-check"></i> Bersedia</button>
                                                            </div>
                                                            <div class="btn-group" v-else-if="data.keterangan_ketersediaan == 'Tidak Bersedia'">
                                                                <b-button v-if="data_rapat_banding?.status_id == 'f1d3f1e8-68ac-11ee-b509-0242ac11000a'" v-on:click="verifikasiKetersediaan('Roleback', data.id, index)" type="button" variant="warning" class="btn-sm"><i class="bx bx-loader"></i> Roleback</b-button>
                                                                <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-times"></i> Tidak Bersedia</button>
                                                            </div>
                                                            <div class="btn-group" v-else>
                                                                <div v-if="data_rapat_banding?.status_id == 'f1d3f1e8-68ac-11ee-b509-0242ac11000a'">
                                                                    <b-button v-on:click="verifikasiKetersediaan('Tidak Bersedia', data.id, index)" type="button" variant="danger" class="btn-sm"><i class="fa fa-times"></i> Tidak Bersedia</b-button>
                                                                    <b-button v-on:click="verifikasiKetersediaan('Bersedia', data.id, index)" type="button" variant="success" class="btn-sm"><i class="fa fa-check"></i> Bersedia</b-button>
                                                                </div>
                                                            </div>
                                                            <div v-if="data.keterangan_ketersediaan == 'Tidak Bersedia' && data.notes">
                                                                <br />
                                                                Alasan Tidak Bersedia: {{ data.notes }}
                                                            </div>
                                                        </div>
                                                        <div v-else-if="data.keterangan_ketersediaan == 'Bersedia'">
                                                            <button class="btn btn-success btn-sm" type="button"><i class="fas fa-check"></i> Bersedia</button>
                                                        </div>
                                                        <div v-else-if="data.keterangan_ketersediaan == 'Tidak Bersedia'">
                                                            <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-times"></i> Tidak Bersedia</button>
                                                        </div>
                                                        <div v-else>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <div v-if="data_rapat_banding.path_surat_tugas != null">
                                                            <b-button @click="ModalPreviewSurat('surat_tugas')" type="button" variant="primary" class="btn-sm">
                                                                <i class="fas fa-eye"></i> Lihat Surat Tugas
                                                            </b-button>
                                                        </div>
                                                        <div v-else>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> 
                                    <div class="col-12 mt-4">
                                        <label for="example-text-input" class="col-form-label mt-4">List Asesmen</label>
                                        <div v-if="data_asesmen.length < 0">
                                            <div class="alert alert-danger" role="alert">
                                                Data Tidak Tersedia
                                            </div>
                                        </div>
                                        <template v-else>
                                            <div class="accordion" v-for="(data, index) in data_asesmen" :key="index" id="accordionExample">
                                                <div class="accordion-item mt-2">
                                                    <h2 class="accordion-header" :id="'heading'+index">
                                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+index" aria-expanded="false" :aria-controls="'collapse'+index">
                                                            {{ data.asesmen_nomor }} - {{ data.skema_nama }}
                                                        </button>
                                                    </h2>
                                                    <div :id="'collapse'+index" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body">
                                                            <div class="col-md-12 mt-3" v-if="data_rapat_banding?.status_id != '5337f168-5b4d-11ee-a5bf-0242ac110007'">
                                                                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                                                    <thead class="bg-dark text-center text-white">
                                                                        <tr>
                                                                            <th>No.</th>
                                                                            <th style="width: 30%">Asesi</th>
                                                                            <th style="width: 30%">Asesor</th>
                                                                            <th>Daftar Dokumen</th>
                                                                            <th>Berita Acara</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-if="data.length == 0">
                                                                            <td class="text-center" colspan="8">Data Tidak Tersedia</td>
                                                                        </tr>
                                                                        <tr v-else v-for="(asesi, index) in data?.rapat_banding_asesi" :key="index">
                                                                            <td class="text-center">
                                                                                {{ index + 1 }}
                                                                            </td>
                                                                            <td class="">
                                                                                {{ asesi?.asesi?.npk }} - {{ asesi.asesi_nama }}
                                                                            </td>
                                                                            <td class="">
                                                                                {{ asesi?.asesor_kompetensi_npk }} - {{ asesi.asesor_kompetensi_nama }}
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <!-- <ol style="list-style-type: decimal">
                                                                                    <li v-for="(dokumen, index_dokumen) in asesi.rapat_banding_dokumen" :key="index_dokumen">
                                                                                        <div class="row">
                                                                                            <div class="col">
                                                                                                {{ dokumen.dokumen_nama }}
                                                                                            </div>
                                                                                            <div class="col text-end">
                                                                                                <div class="row">
                                                                                                    <div class="col">
                                                                                                        <button type="button" v-on:click="getFormDokumen(dokumen.id, dokumen.dokumen_id, asesi.id)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview</button>
                                                                                                    </div>
                                                                                                    <div class="col">
                                                                                                        <div v-if="data_rapat_banding?.status_id == '93a3f29c-5b4d-11ee-a5bf-0242ac110007' && (data_asesor_banding.some((asesor) => asesor.asesor_npk === sessionUsername) || sessionRoleId == '99c668b7-015c-4b68-9761-d2393129d070' || sessionRoleId == '99d70245-6f47-43b6-98d3-b61579bedce1')">
                                                                                                            <button v-if="dokumen.dokumen_id == 'b88cf15e-39dc-11ee-9a36-0242ac110009'" 
                                                                                                            @click="pengisianDokumen(dokumen.id, dokumen.dokumen_id, asesi.id)" type="button" class="btn btn-primary btn-sm m-1 rounded-0"><i class="fa fa-pencil-alt"></i> Lengkapi Dokumen</button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </ol> -->
                                                                                <button type="button" @click="ModalDokumen(asesi.asesi_id ,asesi.asesi_nama, asesi.asesmen_id)" class="btn btn-info btn-sm m-1 rounded-0">
                                                                                    <i class="fa fa-eye"></i> Lihat Dokumen
                                                                                </button>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div class="col">
                                                                                    <button type="button" v-on:click="getFormDokumenBeritaAcara(asesi.asesi_id)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview Berita Acara</button>
                                                                                </div>
                                                                                <div class="col" v-if="data_rapat_banding?.status_id == '65cd4e38-68d9-11ee-9131-0242ac11000a'">
                                                                                    <button @click="pengisianDokumenBeritaAcara(asesi.asesi_id)" type="button" class="btn btn-primary btn-sm m-1 rounded-0"><i class="fa fa-pencil-alt"></i> Lengkapi Berita Acara</button>
                                                                                </div>
                                                                                <div class="col" v-if="data_rapat_banding?.status_id == '10c154a0-697b-11ee-9e92-0242ac11000a'">
                                                                                    <button v-on:click="statusBandingModal('esign-manager-sertifikasi',asesi.asesi_id)" type="button" class="btn btn-primary btn-sm m-1 rounded-0"><i class="fa fa-pencil-alt"></i> Esign Manajer Sertifikasi LSP</button>
                                                                                </div>
                                                                                <div class="col" v-if="data_rapat_banding?.status_id == '475f42d8-697b-11ee-9e92-0242ac11000a'">
                                                                                    <button v-on:click="statusBandingModal('esign-ketua-lsp',asesi.asesi_id)" type="button" class="btn btn-primary btn-sm m-1 rounded-0"><i class="fa fa-pencil-alt"></i> Esign Ketua LSP</button>
                                                                                </div>
                                                                                <!-- <div>
                                                                                    {{ asesi.rekomendasi }}
                                                                                </div> -->
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>  
                                    <!-- <div class="col-md-12 mt-3" v-if="data_rapat_banding?.status_id != '5337f168-5b4d-11ee-a5bf-0242ac110007'">
                                        <label for="tipe_menu">Daftar Asesi</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>No.</th>
                                                    <th style="width: 45%">Asesi</th>
                                                    <th>Daftar Dokumen</th>
                                                    <th>Rekomendasi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="data_asesi.length == 0">
                                                    <td class="text-center" colspan="8">Data Tidak Tersedia</td>
                                                </tr>
                                                <tr v-else v-for="(asesi, index) in data_asesi" :key="index">
                                                    <td class="text-center">
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ asesi?.asesi?.npk }} - {{ asesi.asesi_nama }}
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" @click="ModalDokumen(asesi.rapat_banding_dokumen, asesi.asesi_id ,asesi.asesi_nama)" class="btn btn-info btn-sm m-1 rounded-0">
                                                            <i class="fa fa-eye"></i> Lihat Dokumen
                                                        </button>
                                                    </td>
                                                    <td class="text-center">
                                                        <div>
                                                            {{ asesi.rekomendasi }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="card-footer" v-if="hak_akses">
                            <div class="row">
                                <div class="col-md-6">
                                </div>
                                <div class="col-md-6">
                                    <div class="text-end">
                                        <button v-if="data_rapat_banding?.status_id == '9e431790-68d2-11ee-be2d-0242ac11000a'"
                                             class="btn btn-primary mt-3" @click="ModalPersetujuanKetuaLsp">
                                             <i class="fas fa-save"></i> Simpan
                                        </button>
                                        <!-- <button v-if="data_rapat_banding?.status_id == '8c9dccd0-68d5-11ee-b93b-0242ac11000a' && (data_asesor_banding.some((asesor) => asesor.asesor_npk == sessionUsername) || sessionRoleId == role_admin_lsp || sessionRoleId == role_super_admin)"
                                                @click="ModalDraftBeritaAcara" variant="primary" class="btn btn-primary mt-3">
                                                <i class="fa fa-save"></i> Simpan hasil rapat
                                        </button> -->
                                            
                                        <button v-if="data_rapat_banding?.status_id == '8c9dccd0-68d5-11ee-b93b-0242ac11000a' && (data_asesor_banding.some((asesor) => asesor.asesor_npk == sessionUsername) || sessionRoleId == role_admin_lsp || sessionRoleId == role_super_admin)"
                                                @click="statusBandingModal('submit-proses-investigasi')" variant="primary" class="btn btn-primary mt-3">
                                                <i class="fa fa-save"></i> Proses Submit
                                        </button>
                                        <button v-if="data_rapat_banding?.status_id == '65cd4e38-68d9-11ee-9131-0242ac11000a' && (data_asesor_banding.some((asesor) => asesor.asesor_npk == sessionUsername) || sessionRoleId == role_admin_lsp || sessionRoleId == role_super_admin)"
                                                @click="statusBandingModal('submit-pengisian-berita-acara')" variant="primary" class="btn btn-primary mt-3">
                                                <i class="fa fa-save"></i> Proses Submit
                                        </button>
                                        <b-button v-if="data_rapat_banding?.status_id == 'd7f4f754-5c18-11ee-bc25-0242ac110007'"
                                                @click="ModalEsign" variant="primary" class="m-1">
                                                <i class="fa fa-save"></i> Tanda Tangan Berita Acara
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="my-0">Timeline</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12" style="overflow-x: scroll">
                                <ul class="timeline timeline-horizontal">
                                    <li class="timeline-item" v-for="(val, key) in rapat_banding_timeline" :key="key">
                                        <!--v-if-->
                                        <div class="timeline-badge success">
                                            <i class="fa fa-check" style="margin-top: 12px"></i>
                                        </div>
                                        <div class="timeline-panel">
                                            <div class="timeline-heading">
                                                <b class="timeline-title">{{ val.pejabat_nama }}</b>
                                                <p>
                                                    <small class="text-muted"><i class="fa fa-clock-o"></i> {{ fullDateTimeFormat(val.created_at) }}</small>
                                                </p>
                                            </div>
                                            <div class="badge bg-info">{{ val.status_nama }}</div>
                                            <br />
                                            <div class="timeline-body">
                                                <b>Catatan : </b><br />
                                                <p>{{ val.notes }}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    
    <b-modal v-model="showModalPersetujuanKetuaLsp" title="Preview Surat Tugas" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered table-striped">
                            <tbody>
                                <tr>
                                    <td style="width: 350px"><strong>Nomor Surat Tugas</strong></td>
                                    <td>: {{ data_rapat_banding.rapat_banding_nomor }}</td>
                                    <td rowspan="2" v-if="qr_path" style="width: 150px">
                                        <img v-bind:src="qr_path" alt="" style="width: 100%" />
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Tanggal Surat Tugas</strong></td>
                                    <td>: {{ data_rapat_banding.rapat_banding_tanggal_surat_tugas }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group">
                    <h4>Preview Surat Tugas</h4>
                    <div v-if="showLoading == true"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                    <iframe v-else v-bind:src="preview_surat_tugas" width="100%" height="400"></iframe>
                </div>
                <div class="form-group"><input type="checkbox" v-model="disclaimer_surat_tugas" /> Apakah Anda yakin ingin menerbitkan surat tugas tersebut?</div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="showModalPersetujuanKetuaLsp = false"><i class="fas fa-times"></i> Tutup</button>
                    <button type="button" class="btn btn-primary mt-3" v-if="disclaimer_surat_tugas" @click="ModalPassword"><i class="fas fa-save"></i> Simpan</button>
                    <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalPassword" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <input type="password" class="form-control" v-model="password" placeholder="Password Anda" />
            </div>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-if="data_rapat_banding?.status_id == '26cdb82a-5c19-11ee-bc25-0242ac110007'"
                    v-on:click="statusBandingModal('submit-blanko')"><i class="fas fa-save"></i> Konfirmasi</button>
                <button class="btn btn-primary mt-3" v-else-if="data_rapat_banding?.status_id == '5daf4958-5c19-11ee-bc25-0242ac110007'"
                    v-on:click="statusBandingModal('submit-blanko')"><i class="fas fa-save"></i> Konfirmasi</button>
                    
                <button class="btn btn-primary mt-3" v-else-if="data_rapat_banding?.status_id == 'fe6ffc44-5c18-11ee-bc25-0242ac110007'"
                    v-on:click="statusBandingModal('submit-keputusan')"><i class="fas fa-save"></i> Konfirmasi
                </button>
                <button class="btn btn-primary mt-3" v-else-if="data_rapat_banding?.status_id == '10c154a0-697b-11ee-9e92-0242ac11000a'"
                    v-on:click="statusBandingModal('submit-esign-manager-sertifikasi', asesi_id)"><i class="fas fa-save"></i> Konfirmasi
                </button>
                <button class="btn btn-primary mt-3" v-else-if="data_rapat_banding?.status_id == '475f42d8-697b-11ee-9e92-0242ac11000a'"
                    v-on:click="statusBandingModal('submit-esign-ketua-lsp', asesi_id)"><i class="fas fa-save"></i> Konfirmasi
                </button>
                <button class="btn btn-primary mt-3" v-else 
                    v-on:click="statusBandingModal('submit-ketua-lsp')"><i class="fas fa-save"></i> Konfirmasi</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalPasswordAsesor" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <input type="password" class="form-control" v-model="password" placeholder="Password Anda" />
            </div>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-if="data_rapat_banding?.status_id != 'd7f4f754-5c18-11ee-bc25-0242ac110007'"
                    @click="statusBandingModal('submit-draft-berita-acara')">
                    <i class="fas fa-save"></i> Konfirmasi
                </button>
                <button class="btn btn-primary mt-3" v-else 
                    @click="statusBandingModal('submit-esign-berita-acara')">
                    <i class="fas fa-save"></i> Konfirmasi
                </button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalDraftBeritaAcara" title="Berita Acara" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <label for="tipe_menu">Daftar Asesi</label>
                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                    <thead class="bg-dark text-center text-white">
                        <tr>
                            <th style="width: 15px">No.</th>
                            <th style="width: auto" >Asesi</th>
                            <th style="width: auto" >No Registrasi</th>
                            <th style="width: auto" >Asesor Kompetensi</th>
                            <th style="width: 200px">Rekomendasi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(asesi, index) in data_asesi" :key="index">
                            <td class="text-center">
                                {{ index + 1 }}
                            </td>
                            <td class="text-center">
                                {{ asesi?.asesi?.npk }} - {{ asesi.asesi_nama }}
                            </td>
                            <td class="text-center">
                                {{ asesi.no_registrasi }}
                            </td>
                            <td class="text-center">
                                {{ asesi.asesor_kompetensi_npk }} - {{ asesi.asesor_kompetensi_nama }}
                            </td>
                            <td>
                                <v-select :options="['K', 'BK']" v-model="asesi.rekomendasi" placeholder="Pilih Hasil Rekomendasi"> </v-select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <div class="form-group">
                <!-- <div v-if="showLoading">
                    <i class="fas fa-spinner fa-spin"></i> Loading Generate Surat Tugas.....
                </div> -->
                <div v-if="showPreviewBeritaAcara" class="form-group">
                    <h4>Preview berita Acara</h4>
                    <div class="card-body">
                        <iframe v-bind:src="preview_berita_acara" width="100%" height="400"></iframe>
                    </div>
                </div>
                <div v-else class="form-group text-end">
                    <button type="button" class="btn btn-primary mt-3" @click="generateDraftBeritaAcara" ><i class="fas fa-save"></i> Generate Berita Acara</button>
                </div>
            </div>

            </div>
            <div v-if="showPreviewBeritaAcara" class="form-group"><input type="checkbox" v-model="disclaimer_berita_acara" /> Apakah Anda yakin ingin menerbitkan Berita acara tersebut?</div>
            <div v-if="showPreviewBeritaAcara" class="form-group text-end">
                <button type="button" class="btn btn-secodary mt-3" @click="showModalDraftBeritaAcara = false"><i class="fas fa-times"></i> Tutup</button>
                <button type="button" class="btn btn-primary mt-3" v-if="disclaimer_berita_acara" @click="ModalPasswordAsesor"><i class="fas fa-save"></i> Simpan</button>
                <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalEsignBeritaAcara" title="Draft Berita Acara" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <!-- <label for="tipe_menu">Daftar Asesi</label>
                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                    <thead class="bg-dark text-center text-white">
                        <tr>
                            <th style="width: 15px">No.</th>
                            <th>Asesi</th>
                            <th style="width: 200px">Rekomendasi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(asesi, index) in data_asesi" :key="index">
                            <td class="text-center">
                                {{ index + 1 }}
                            </td>
                            <td class="text-center">
                                {{ asesi.asesi_nama }}
                            </td>
                            <td>
                                <v-select :options="['Kompeten', 'Belum Kompeten']" v-model="asesi.rekomendasi" placeholder="Pilih Hasil Rekomendasi"> </v-select>
                            </td>
                        </tr>
                    </tbody>
                </table> -->
                <table class="table table-bordered table-striped">
                <tbody>
                    <tr>
                        <td style="width: 350px"><strong>Nomor Berita Acara</strong></td>
                        <td>: {{ data_rapat_banding.rapat_banding_nomor }}</td>
                        <td rowspan="2" v-if="qr_path" style="width: 150px">
                            <img v-bind:src="qr_path" alt="" style="width: 100%" />
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Tanggal Berita Acara</strong></td>
                        <td>: {{ data_rapat_banding.rapat_banding_tanggal }}</td>
                    </tr>
                </tbody>
            </table>
                
                <div class="form-group">
                <!-- <div v-if="showLoading">
                    <i class="fas fa-spinner fa-spin"></i> Loading Generate Surat Tugas.....
                </div> -->
                <h4>Preview berita Acara</h4>
                <div class="card-body">
                    <iframe v-bind:src="preview_berita_acara" width="100%" height="400"></iframe>
                </div>
            </div>

            </div>
            <div class="form-group"><input type="checkbox" v-model="disclaimer_berita_acara" /> Apakah Anda yakin ingin menerbitkan Berita acara tersebut?</div>
            <div class="form-group text-end">
                <button type="button" class="btn btn-secodary mt-3" @click="showModalDraftBeritaAcara = false"><i class="fas fa-times"></i> Tutup</button>
                <button type="button" class="btn btn-primary mt-3" v-if="disclaimer_berita_acara" @click="ModalPasswordAsesor"><i class="fas fa-save"></i> Simpan</button>
                <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalSuratKeputusan" title="Surat keputusan" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <h4>Preview Surat Keputusan</h4>
                <div class="card-body">
                    <iframe v-bind:src="preview_surat_keputusan" width="100%" height="400"></iframe>
                </div>
            </div>

            <div class="form-group"><input type="checkbox" v-model="disclaimer_surat_keputusan" /> Apakah Anda yakin ingin menerbitkan Surat Keputusan tersebut?</div>
            <div class="form-group text-end">
                <button type="button" class="btn btn-secodary mt-3" @click="showModalSuratKeputusan = false"><i class="fas fa-times"></i> Tutup</button>
                <button type="button" class="btn btn-primary mt-3" v-if="disclaimer_surat_keputusan" @click="ModalPassword"><i class="fas fa-save"></i> Simpan</button>
                <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalBlanko" title="Blanko sertifikasi Kompetensi" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <h4>Preview Blanko sertifikasi kompetensi</h4>
                <div class="card-body">
                    <iframe v-bind:src="preview_blanko" width="100%" height="400"></iframe>
                </div>
            </div>

            <div class="form-group"><input type="checkbox" v-model="disclaimer_blanko" /> Apakah Anda yakin ingin menerbitkan Blanko tersebut</div>
            <div class="form-group text-end">
                <button type="button" class="btn btn-secodary mt-3" @click="showModalBlanko = false"><i class="fas fa-times"></i> Tutup</button>
                <button type="button" class="btn btn-primary mt-3" v-if="disclaimer_blanko" @click="ModalPassword"><i class="fas fa-save"></i> Simpan</button>
                <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalDokumen" :title="'Dokumen Asesi: ' + asesi_nama" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <div v-if="loadingShowModalDokumen">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                </div>
                <div class="card-body" v-else>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center">No.</th>
                                <th class="text-center">Nama Dokumen</th>
                                <!-- <th> urutan</th> -->
                                <th class="text-center">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(dokumen, index_dokumen) in data_dokumen" :key="index_dokumen">
                                <td>{{ index_dokumen + 1 }}</td>
                                <td>{{ dokumen.dokumen_nama }}</td>
                                <!-- <td>{{ dokumen.urutan }}</td> -->
                                <td>
                                    <button type="button" v-on:click="getFormDokumen(dokumen.id, dokumen.dokumen_id, dokumen.asesmen_asesi_id)" class="btn btn-info btn-sm m-1 rounded-0">
                                        <i class="fa fa-eye"></i> Preview
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalPreviewSurat" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
            <div class="row">
                <div class="form-group">
                    <div class="card-body">
                        <iframe v-bind:src="preview_surat" width="100%" height="400"></iframe>
                    </div>
                </div>
            </div>
    </b-modal>
    <b-modal v-model="showModalPengisianDokumen" title="Pengisian Berita Acara Pelaksanaan Investigasi" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <form id="form-dokumen">
                <div class="form-group">
                    <div v-html="form_dokumen_muk"></div>
                </div>
            </form>
            <div class="form-group text-end">
                <button class="btn btn-primary mt-3" v-on:click="storeInputDokumen"><i class="fas fa-save"></i> Simpan</button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalPreviewDokumen" title="Preview Dokumen" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
        <div class="row">
            <form @submit.prevent="StorePreviewMUK">
                <div v-if="loadingformPreviewDoc"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                <iframe v-else v-bind:src="formPreviewDoc" width="100%" height="400"></iframe>
                <div class="card-footer text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="closeModalPreviewDokumen"><i class="fas fa-times"></i> Tutup</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalPreviewDokumenBeritaAcara" title="Preview Dokumen Berita Acara" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
        <div class="row">
            <form @submit.prevent="StorePreviewMUK">
                <div v-if="loadingformPreviewDoc"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                <iframe v-else v-bind:src="formPreviewDoc" width="100%" height="400"></iframe>
                <div class="card-footer text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="closeModalPreviewDokumenBeritaAcara"><i class="fas fa-times"></i> Tutup</button>
                </div>
            </form>
        </div>
    </b-modal>
    
</template>
<style scoped>
.col-md-custom {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
}

.list-inline{
    margin-top: 10px;
}

.hori-timeline .events {
    border-top: 3px solid #e9ecef;
}

.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    margin-right: 0;
}

.hori-timeline .events .event-list:before {
    content: "";
    position: absolute;
    height: 36px;
    border-right: 2px dashed #dee2e6;
    top: 0;
}

.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    width: 100px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}

@media (min-width: 1140px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 45px;
    }

    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}

.bg-soft-primary {
    background-color: rgba(64, 144, 203, .3) !important;
}

.bg-soft-success {
    background-color: rgba(71, 189, 154, .3) !important;
}

.bg-soft-danger {
    background-color: rgba(231, 76, 94, .3) !important;
}

.bg-soft-warning {
    background-color: rgba(249, 213, 112, .3) !important;
}

.card {
    border: none;
    margin-bottom: 24px;
    -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
    box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
}
.scroll-timeline{
    overflow: auto;
    white-space: nowrap;
}
</style>

